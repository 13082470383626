import React from "react"
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import ProjectCard from "../components/projectCard"
import Container from "../components/container"

import { css } from '@emotion/react'
import PersonCard from "../components/personCard"
import BookCard from "../components/bookCard"
import MediaCard from "../components/mediaCard"
import ThreeColumns from "../components/layouts/threeColumns"
import FourColumns from "../components/layouts/fourColumns"
// import CityScape from "../components/svg/cityscape"


const IndexPage = (props) => {
  const projects = props.data.projects.edges.map( edge => edge.node )
  const people = props.data.people.edges.map( edge => edge.node ).filter( node => node.data.Image )
  const books = props.data.books.edges.map( edge => edge.node )
  const podcasts = props.data.podcasts.edges.map( edge => edge.node )
  const sdgs = props.data.sdgs.edges.map( edge => edge.node )
  const verticals = props.data.verticals.edges.map( edge => edge.node )
  const push = props.data.push.edges.map( edge => edge.node )
  
  // console.log(props)

  const projectsCount = props.data.projects.totalCount
  const peopleCount = props.data.people.totalCount
  const booksCount = props.data.books.totalCount
  const podcastsCount = props.data.podcasts.totalCount

  return (
    <Layout>
      <Seo title="Home" />
  
      <Hero 
        title={`Une monde plus juste et durable est à portée de job !`}
        subtitle={`S'orienter, se réorienter, se former, s'inspirer... mettez votre talent et vos semaines de travail au service de la transition énergétique, écologique et sociale.`}
        link_text={`Voir les projets 🇨🇭`}
        link={`/projets`}
        // image={<CityScape />}
        carousel={push}
      />

      <div style={{padding: "66px 20px", backgroundColor: "#edfff9", textAlign: "center"}}>
        <div style={{margin: "0 auto", maxWidth: "800px"}}>
          <h2>🥁 Changer de job pour changer le monde</h2>
          <p>Nous passons en moyenne 40 heures à travailler par semaine, 1600h par an, parfois avec trop peu de plaisir ou sans impact clair. Et si... notre force de travail, créativité, talent étaient à 100% utilisés pour des emplois à fort impact social et environnemental ?</p>
          <p>Ce n'est certainement pas si simple, mais nous avons voulu essayer de rassembler ces métiers et entreprises sur une plateforme. Juste pour voir. Kobbble est la plateforme dédiée aux métiers et entreprises/organisations qui permettent de relever les défis de la transition écologique et sociale, tout en y trouvant du sens et du plaisir au travail.</p>
        </div>
      </div>

      <Container>
        <div css={sdgSection}>
          <h2>🎯 À quel objectif contribuer pour la transition en Suisse ?</h2>
          <p>Les objectifs de développement durable (<i>Sustainable Development Goals  - SDGs</i>) sont établis par l'Organisation des Nations Unies pour classifier les actions possibles pour un futur plus juste et durable. Tous les projets sur Kobbble sont classifiés en utilisant cette méthodologie.</p>
          <div>
            {sdgs.sort( (sdg1, sdg2) => sdg1.data.Number - sdg2.data.Number ).map( sdg => (
              <Link to={`/objectif-developpement-durable-${sdg.data.Number}`} key={sdg.id}>
                <GatsbyImage image={sdg.data.Icon.localFiles[0].childImageSharp.gatsbyImageData} alt={sdg.data.Name} />
                <div className="sdg__p-nbr">{sdg.data.Projects ? sdg.data.Projects.filter( p => p.data.Countries && p.data.Countries.includes("Suisse") ).length : "0"}</div>
              </Link>
            ))}
          </div>
        </div>

        <div css={[sdgSection, verticalsSection]}>
          <h2>🪴 Un secteur de prédilection pour la transition en Suisse ?</h2>
          <p>Chaque secteur tient une part de reponsabilité dans le déroulement efficace de la transition, avec des objectifs de réduction des émission de C02 clairs.</p>
          <div>
            {verticals.sort( (a, b) => a.data.Slug.localeCompare(b.data.Slug) ).map( vertical => (
              <Link to={`/${vertical.data.Slug}`} key={vertical.id}>
                {vertical.data.Icon && <GatsbyImage image={vertical.data.Icon.localFiles[0].childImageSharp.gatsbyImageData} alt={vertical.data.Name} />}
                <div className="sdg__p-nbr">{vertical.data.Projects ? vertical.data.Projects.filter( p => p.data.Countries && p.data.Countries.includes("Suisse") ).length : "0"}</div>
              </Link>
            ))}
          </div>
        </div>

        <div style={{marginTop: "100px"}}>
          <h2>💡 Plus de {projectsCount} projets inspirants en Suisse</h2>
          <FourColumns>
            {projects.map( project => {
              if (project.data.Name && project.data.Cover && project.data.Logo) {
                return (
                  <ProjectCard 
                    key={project.id}
                    slug={project.data.Slug}
                    name={project.data.Name}
                    pitch={project.data.Pitch}
                    cover={project.data.Cover.localFiles && project.data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                    logo={project.data.Logo.localFiles && project.data.Logo.localFiles[0].childImageSharp.gatsbyImageData}
                    sectors={project.data.Sectors}
                    devGoals={project.data.SDGs && project.data.SDGs.map( obj => ({
                      id: obj.id,
                      nbr: obj.data.Number,
                    }))}
                  />
                )
              }
              return null
            })}
            <Link to={'/projets'} css={moreLink}>Découvrir les {projectsCount - 7} autres projets</Link>
          </FourColumns>
        </div>

        {false && <div style={{marginTop: "100px"}}>
          <h2>💪 {peopleCount} entrepreneur.e.s inspirant.e.s</h2>
          <ThreeColumns>
            {people.map( person => (
              <PersonCard 
                key={person.id}
                name={person.data.Name}
                // short_bio={person.data.ShortBio}
                picture={person.data.Image && person.data.Image.localFiles[0].childImageSharp.gatsbyImageData}
                projects={person.data.Projects && person.data.Projects.map( project => project.data.Name )}
                educations={person.data.Educations && person.data.Educations.map( edu => edu.data.Name )}
              />
            ))}
            <Link to={'/makers'} css={moreLink}>Découvrir les {peopleCount - 5} autres change makers</Link>
          </ThreeColumns>
        </div>}

        <div style={{marginTop: "100px", marginBottom: "100px"}}>
          <h2>📚 {booksCount} bonnes lectures</h2>
            <ThreeColumns>
              {books.map( book => (
                <BookCard 
                  key={book.id}
                  name={book.data.Name}
                  description={book.data.Description}
                  show_desc={false}
                  year={book.data.Year}
                  image={book.data.Cover.localFiles && book.data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                  authors={book.data.People && book.data.People.map( person => person.data.Name )}
                  authors_img={book.data.People && book.data.People.map( person => person.data.Image && person.data.Image.localFiles[0].childImageSharp.gatsbyImageData ).filter( url => url != null )}
                />
              ))}
              <Link to={'/inspirations'} css={moreLink}>Découvrir les {booksCount - 5} autres bonnes lectures</Link>
            </ThreeColumns>
        </div>

        {false && <div style={{marginTop: "100px", marginBottom: "100px"}}>
          <h2>🎙 {podcastsCount} contenus à voir ou écouter</h2>
          <ThreeColumns>
            {podcasts.map( podcast => (
              <MediaCard 
                key={podcast.id}
                name={podcast.data.Name}
                image={podcast.data.Image && podcast.data.Image[0].localFiles.childImageSharp.gatsbyImageData}
                link={podcast.data.Link}
                type={`Podcast`}
                person={podcast.data.People && podcast.data.People[0].data.Name}
                person_img={podcast.data.People && podcast.data.People[0].data.Image && podcast.data.People[0].data.Image[0].localFiles.childImageSharp.gatsbyImageData}
              />
            ))}
            <Link to={'/inspirations#podcasts'} css={moreLink}>Découvrir les {podcastsCount - 2} autres podcasts</Link>
          </ThreeColumns>
        </div>}
      </Container>
    </Layout>
  )
}

export default IndexPage

export const IndexQuery = graphql`
  query IndexQuery {
    projects: allAirtable(filter: {table: {eq: "Projects"}, id: {ne: "null"}, data: {Countries: {eq: "Suisse"}, Status: {eq: "Published"}}}, limit: 7) {
      totalCount
      edges {
        node {
          ...ProjectCardFragment
        }
      }
    }
    people: allAirtable(filter: {table: {eq: "People"}}, limit: 5) {
      totalCount
      edges {
        node {
          ...PeopleCardFragment
        }
      }
    }
    books: allAirtable(filter: {table: {eq: "Books"}, data: {Status: {eq: "Published"}}}, limit: 5, sort: {fields: data___Year, order: DESC}) {
      totalCount
      edges {
        node {
          ...BookCardFragment
        }
      }
    }
    podcasts: allAirtable(filter: {table: {eq: "PodcastShows"}, data: {Status: {eq: "Published"}}}, limit: 2) {
      totalCount
      edges {
        node {
          id
          data {
            Name
            Image {
              id
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                      width: 150
                      quality: 100
                  )
                }
              }
            }
            People {
              data {
                Name
                Image {
                  id
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(
                          width: 50
                          quality: 100
                      )
                    }
                  }
                }
              }
            }
            Link
          }
        }
      }
    }
    sdgs: allAirtable(filter: {table: {eq: "SDGs"}}) {
      edges {
        node {
          id
          data {
            Name
            Number
            Icon {
              id
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                      width: 300
                      quality: 100
                  )
                }
              }
            }
            Projects {
              id
              data {
                Countries
              }
            }
          }
        }
      }
    }
    verticals: allAirtable(filter: {table: {eq: "Verticals"}}) {
      edges {
        node {
          id
          data {
            Slug
            Name
            Description
            Icon {
              id
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                      width: 300
                      quality: 100
                  )
                }
              }
            }
            Projects {
              id
              data {
                Countries
              }
            }
          }
        }
      }
    }
    push: allAirtable(
      filter: {table: {eq: "Projects"}, data: {Status: {eq: "Published"}, Push: {gt: 0}}}
    ) {
      edges {
        node {
          ...ProjectCardFragment
        }
      }
    }
  }
`

const moreLink = css`
  border-radius: 5px;
  border: 1px solid #00ff95;
  background-color: #e6fff7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: black;
  transition: background-color .2s ease-in-out;
  padding: 20px;

  :hover {
    background-color: #00ff95;
    // color: white;
  }
`

const sdgSection = css`
  margin-top: 100px;

  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 15px;

    @media screen and (min-width: 800px) {
      grid-template-columns: repeat(9, 1fr);
    }

    a, img {
      margin: 0;
      line-height: 0;
    }

    img {
      border-radius: 5px;
    }

    a {
      position: relative;

      .sdg__p-nbr {
        position: absolute;
        bottom: 10px;
        right: 10px;
        text-decoration: none;
        color: white;
        background-color: rgba(255, 255, 255, .32);
        border-radius: 5px;
        font-size: .8rem;
        padding: 10px 5px;
      }
    }
  }
`

const verticalsSection = css`
  > div {
    grid-template-columns: repeat(3, 1fr);

    @media screen and (min-width: 800px) {
      grid-template-columns: repeat(7, 1fr);
    }
  }
`