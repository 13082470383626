import React, { useState, useEffect } from "react"

import { css } from '@emotion/react'
import Container from "./container"
import Button from "./button"
import ProjectCard from "./projectCard"
import tilesBackground from "../images/bg-tile--big-0103.svg"


const Hero = (props) => {
    const {
        title,
        subtitle,
        link_text,
        link,
        carousel,
    } = props

    const [slide, setSlide] = useState(0)

    useEffect(() => {
        const sliderInterval = setInterval(() => {
            setSlide(prev => prev < carousel.length-1 ? prev+1 : 0)
        }, 3333)

        return () => clearInterval(sliderInterval)
    }, [carousel.length])

    // console.log(carousel)

    return (
        <div css={main}>
            <Container>
                <div css={flexTwo}>
                    <div css={left}>
                        <h1>{title}</h1>
                        <p>{subtitle}</p>
                        <Button alt link={link} text={link_text} />
                    </div>
                    
                    {carousel && <div css={right}>
                        <div css={story}>
                            <div css={storyTimeline}>
                                {carousel.map( (step, index) => (
                                    <div key={step.id} css={storyStep}>
                                        {index === slide && <div></div>}
                                    </div>
                                ))}
                            </div>
                            <div key={carousel[slide].id}>
                                <ProjectCard 
                                    boost={true}
                                    slug={carousel[slide].data.Slug}
                                    name={carousel[slide].data.Name}
                                    pitch={carousel[slide].data.Pitch}
                                    cover={carousel[slide].data.Cover.localFiles && carousel[slide].data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                                    logo={carousel[slide].data.Logo.localFiles && carousel[slide].data.Logo.localFiles[0].childImageSharp.gatsbyImageData}
                                    eager
                                    sectors={carousel[slide].data.Sectors}
                                    devGoals={carousel[slide].data.SDGs && carousel[slide].data.SDGs.map( obj => ({
                                        id: obj.id,
                                        nbr: obj.data.Number,
                                    }))}
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            </Container>
        </div>
    )
}

export default Hero

const main = css`
    background-color: #00ffaa;
    // background-color: #D1BAA0;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    min-height: 66vh;
    background-image: url(${tilesBackground});
`

const flexTwo = css`
    @media screen and (min-width: 1000px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const left = css`
    max-width: 700px;
    margin-bottom: 30px;

    h1 {
        font-size: 2rem;

        @media screen and (min-width: 1000px) {
            font-size: 3.4rem;
        }
    }

    @media screen and (min-width: 1000px) {
        padding-right: 50px;
        margin-bottom: 0;
    }
`

const right = css`
    width: 100%;

    @media screen and (min-width: 1000px) {
        min-width: 350px;
        width: 450px;
    }
`




const story =  css`
    position: relative;
`

const storyTimeline =  css`
    position: absolute;
    z-index: 10;
    top: 20px;
    left: 17px;
    right: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const storyStep =  css`
    background-color: rgba(255, 255, 255, .33);
    border-radius: 10px;
    height: 3px;
    flex-grow: 1;
    margin: 0 3px;
    position: relative;
    overflow: hidden;

    @keyframes storyloading {
        from {right: 100%;}
        to {right: 0%;}
    }

    div {
        background-color: white;
        border-radius: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 3px;
        width: 100%;
        animation-name: storyloading;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }
`